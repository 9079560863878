var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"links",style:([
        this.$vuetify.theme.dark
            ? { background: ("" + (this.$vuetify.theme.themes.dark.textColor)) }
            : {
                  background: ("" + (this.$vuetify.theme.themes.light.textColor)),
              } ]),attrs:{"id":"links"}},[(this.$vuetify.breakpoint.smAndDown || _vm.isTouchScreen)?_c('div',{staticClass:"links-component",style:([
            {
                background: ("" + (this.$vuetify.theme.currentTheme.backgroundColor)),
                color: ("" + (this.$vuetify.theme.currentTheme.textColor)),
            } ])},_vm._l((_vm.links),function(link){return _c('div',{key:link.icon,staticClass:"link-card"},[_c('v-btn',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"btn",attrs:{"href":link.url,"target":"_blank","x-large":"","depressed":"","color":_vm.$vuetify.theme.currentTheme.aboutBackground}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(link.icon))])],1)],1)}),0):_vm._e(),(!this.$vuetify.breakpoint.smAndDown && !_vm.isTouchScreen)?_c('div',{staticClass:"links-component larger-screen-height"},[_c('div',{staticClass:"links-container",style:([
                {
                    background: ("" + (this.$vuetify.theme.currentTheme.aboutBackground)),
                    color: ("" + (this.$vuetify.theme.currentTheme.textColor)),
                } ])},_vm._l((_vm.links),function(link){return _c('v-btn',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],key:link.icon,staticClass:"link-btn",attrs:{"href":link.url,"target":"_blank","fab":"","depressed":"","color":("" + (_vm.$vuetify.theme.currentTheme.aboutBackground))}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(link.icon))])],1)}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }