<template>
    <div class="about" id="about">
        <div
            class="about-content"
            :style="[
                {
                    background: `${this.$vuetify.theme.currentTheme.aboutBackground}`,
                    color: `${this.$vuetify.theme.currentTheme.textColor}`,
                },
            ]"
        >
            <svg class="svg-wave-border">
                <clipPath id="wave" clipPathUnits="objectBoundingBox">
                    <path
                        class="st0"
                        d="M 1 0 C 1 0 0.7 0.1 0.5 0.1 S 0.3 0 0 0.1 V 1 H 0 C 1 1 0.9 0.9 1 0.9 L 1 0 Z"
                    />
                </clipPath>
            </svg>

            <div class="about-description">
                <div v-scrollanimation>
                    Hi again! I’m a web developer who loves to build stuff.
                    Recently relocated to Stockholm after many years in London,
                    where I was fortunate to work for an award-winning start-up
                    delivering enterprise software for the home care industry.
                </div>
                <div v-scrollanimation:name="'from-bottom'">
                    Though mainly focused on JavaScript in different outfits
                    like TypeScript, Angular, React and Vue.js, I’m always eager
                    to learn something new, so whatever you have in mind, let's
                    chat. ↓
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about {
    width: 100%;
    height: 100vh;
    background: linear-gradient(transparent 50%, var(--v-background-base) 50%);
    box-shadow: none;
    position: relative;
    z-index: 2;

    .about-content {
        font-size: 1rem;
        z-index: 2;
        position: relative;
        min-height: 100vh;
        width: 100%;
        left: 0;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        clip-path: url(#wave);

        .svg-wave-border {
            height: 0;
        }

        .about-description {
            margin: 16px 64px;
            overflow: hidden;
            max-width: 32rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            :first-child {
                margin-bottom: 1.5rem;
            }

            @keyframes slide-in-right {
                0% {
                    opacity: 0;
                    transform: translateX(30px);
                }

                100% {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }

            @keyframes slide-up {
                0% {
                    opacity: 0;
                    transform: translateY(30px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            .before-scroll-enter {
                opacity: 0;
            }

            .scroll-enter {
                opacity: 1;

                animation-name: slide-in-right;
                animation-duration: 1.5s;
                animation-timing-function: ease-out;
            }

            .before-scroll-enter-from-bottom {
                opacity: 0;
            }

            .scroll-enter-from-bottom {
                opacity: 1;

                animation-name: slide-up;
                animation-duration: 1.5s;
                animation-timing-function: ease-out;
            }
        }
    }
}
</style>
