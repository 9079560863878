<template>
    <div
        class="links"
        id="links"
        :style="[
            this.$vuetify.theme.dark
                ? { background: `${this.$vuetify.theme.themes.dark.textColor}` }
                : {
                      background: `${this.$vuetify.theme.themes.light.textColor}`,
                  },
        ]"
    >
        <div
            class="links-component"
            v-if="this.$vuetify.breakpoint.smAndDown || isTouchScreen"
            :style="[
                {
                    background: `${this.$vuetify.theme.currentTheme.backgroundColor}`,
                    color: `${this.$vuetify.theme.currentTheme.textColor}`,
                },
            ]"
        >
            <div class="link-card" v-for="link in links" :key="link.icon">
                <v-btn
                    class="btn"
                    :href="link.url"
                    target="_blank"
                    x-large
                    depressed
                    v-scrollanimation
                    :color="$vuetify.theme.currentTheme.aboutBackground"
                >
                    <v-icon x-large>{{ link.icon }}</v-icon>
                </v-btn>
            </div>
        </div>

        <div
            class="links-component larger-screen-height"
            v-if="!this.$vuetify.breakpoint.smAndDown && !isTouchScreen"
        >
            <div
                class="links-container"
                :style="[
                    {
                        background: `${this.$vuetify.theme.currentTheme.aboutBackground}`,
                        color: `${this.$vuetify.theme.currentTheme.textColor}`,
                    },
                ]"
            >
                <v-btn
                    class="link-btn"
                    v-for="link in links"
                    :href="link.url"
                    target="_blank"
                    :key="link.icon"
                    fab
                    depressed
                    v-scrollanimation
                    :color="`${$vuetify.theme.currentTheme.aboutBackground}`"
                >
                    <v-icon large>{{ link.icon }}</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data: () => ({
        isTouchScreen: window.matchMedia('(hover: none)').matches,
        links: [
            {
                icon: 'mdi-github',
                url: 'https://github.com/elnorstrom',
                backgroundColor: 'githubBackground',
            },
            {
                icon: 'mdi-linkedin',
                url: 'https://www.linkedin.com/in/eliasnorstrom',
                backgroundColor: 'linkedinBackground',
            },
        ],
    }),
});
</script>

<style lang="scss" scoped>
.links {
    -webkit-transform: translate3d(0, 0, 0);

    .links-component {
        z-index: 3;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20vh;

        .before-scroll-enter {
            opacity: 0;
            // transform: translateY(200px);
            transition: opacity 1s ease-out;
        }

        .scroll-enter {
            opacity: 1;
            // transform: translateY(0px);
        }

        .link-card {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: none;
            z-index: 2;
            position: relative;

            &:first-child {
                margin-right: 2px;
            }
            &:last-child {
                margin-left: 2px;
            }

            .v-btn {
                width: 100%;
                border-radius: 0px;
                flex: 1;
            }
        }

        .links-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .link-btn {
                margin-left: 1.5rem;
                margin-right: 1.5rem;
            }
        }
    }

    .larger-screen-height {
        height: 12vh;
        min-height: 78px;
    }
}
</style>
