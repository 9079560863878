import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                background: '#FFFFFF',
                textColor: '#000000',
                aboutBackground: '#ffe223',
                githubBackground: '#f6f8fa',
                linkedinBackground: '#f6f8fa',
            },
            dark: {
                background: '#121212',
                textColor: '#FFFFFF',
                aboutBackground: '#1d4070',
                githubBackground: '#2f363d',
                linkedinBackground: '#2f363d',
            },
        },
    },
});
