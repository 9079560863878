









import Vue from 'vue';

import Landing from '@/components/Landing.vue';
import About from '@/components/About.vue';
import Contact from '@/components/Contact.vue';
import Links from '@/components/Links.vue';

export default Vue.extend({
    name: 'Home',
    components: {
        Landing,
        About,
        Contact,
        Links,
    },
});
