import { DirectiveBinding } from 'vue/types/options';

/**
 * ScrollAnimator class, initializes IntersectionObserver
 * (animatedScrollObserver) and adds before and enter class names, with
 * optional custom name if provided.
 */
class ScrollAnimator {
    public el: HTMLElement;
    public animatedScrollObserver: IntersectionObserver;

    private beforeScrollEnter = 'before-scroll-enter';
    private scrollEnter = 'scroll-enter';
    private name: string | null = null;

    constructor(el: HTMLElement, name: string | null = null) {
        this.el = el;
        this.name = name;
        this.animatedScrollObserver = this.createAnimatedScrollObserver();
    }

    /**
     * Update the beforeScrollEnter and scrollEnter css class names by
     * concatenating the defaults with a custom name (if provided).
     */
    updateClassNames(): void {
        if (this.name) {
            this.beforeScrollEnter = `${this.beforeScrollEnter}-${this.name}`;
            this.scrollEnter = `${this.scrollEnter}-${this.name}`;
        }

        this?.el?.classList.add(this.beforeScrollEnter);
    }

    /**
     * Initilizes a IntersectionObserver, add 'scroll-enter' css class when
     * intersecting and unobserves the entry target
     */
    createAnimatedScrollObserver(): IntersectionObserver {
        this.updateClassNames() as void;

        return new IntersectionObserver(
            (
                entries: IntersectionObserverEntry[],
                animatedScrollObserver: IntersectionObserver
            ) => {
                entries.forEach((entry: IntersectionObserverEntry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(this.scrollEnter) as void;
                        animatedScrollObserver.unobserve(entry.target) as void;
                    }
                });
            }
        );
    }
}

/**
 * ScrollAnimation Directive
 */
export default {
    bind(el: HTMLElement, binding: DirectiveBinding) {
        const name: string | null =
            binding.arg === 'name' ? binding.value : null;

        const scrollAnimator: ScrollAnimator = new ScrollAnimator(el, name);

        if (scrollAnimator.animatedScrollObserver) {
            scrollAnimator.animatedScrollObserver.observe(el) as void;
        }
    },
};
