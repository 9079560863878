

























































import Vue from 'vue';

//Type definitions
interface ContactComponentDataState {
    userPart1: string;
    userPart2: string;
    domain: string;
    tld: string;
    copied: boolean;
    snackbar: boolean;
    timeout: number;
    isTouchScreen: boolean;
}

type EmailAddressComputedMethod = () => string;

export default Vue.extend({
    // name: 'Contact',
    data: (): ContactComponentDataState => ({
        userPart1: 'elias',
        userPart2: 'norstromm',
        domain: 'gmail',
        tld: 'com',
        copied: false,
        snackbar: false,
        timeout: 3000,
        isTouchScreen: window.matchMedia('(hover: none)').matches,
    }),
    computed: {
        address(): string {
            return `${this.userPart1}.${this.userPart2}@${this.domain}.${this.tld}`;
        },
    },
    methods: {
        onEmailClick(): void {
            window.location.href = 'mailto:' + this.address;
        },
        onCopyClick(): void {
            console.log('copy clicked');
            this.copied = true;

            this.snackbar = true;
            setTimeout(() => (this.copied = false), this.timeout);

            if (
                (window.navigator as Navigator) &&
                (navigator.clipboard as Clipboard)
            ) {
                navigator.clipboard.writeText(
                    this.address as string
                ) as Promise<void>;
                console.log('navigator used!');
            } else {
                const el: HTMLTextAreaElement | null = document.createElement(
                    'textarea'
                );
                el.value = this.address;
                el.style.position = 'absolute';
                el.style.left = '-9999px';

                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                console.log('create element used!');
            }
        },
    },
});
