import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '*',
        redirect: '/',
    },

    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ '../views/About.vue'),
    // },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            const element = document.getElementById(to.hash.slice(1));
            if (element) {
                return window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth',
                });
            }
        }
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
});

export default router;
