










































































































































































































































import Vue from 'vue';
import smoothscroll from 'smoothscroll-polyfill';

export const PREFERS_DARK_COLOR_SCHEME = '(prefers-color-scheme: dark)';

// Type definitions
type ColorPreferceToggle = (e: MediaQueryListEvent) => void;

interface AppComponentDataState {
    perfersDarkColor: boolean;
    defaultToSystemStyle: boolean;
    themeToggleTouched: boolean;
    darkMediaQuery: MediaQueryList;
    drawer: boolean;
    isTouchScreen: boolean;
}

export default Vue.extend({
    name: 'App',
    data: (): AppComponentDataState => ({
        perfersDarkColor: false,
        defaultToSystemStyle: true,
        themeToggleTouched: false,
        darkMediaQuery: window.matchMedia(PREFERS_DARK_COLOR_SCHEME),
        drawer: false,
        isTouchScreen: window.matchMedia('(hover: none)').matches,
    }),
    created() {
        smoothscroll.polyfill();

        const colorPreference = this.checkColorPreference();

        if (colorPreference) {
            this.setThemeByUser(colorPreference);
        } else {
            this.setThemeBySystem();
        }
    },
    watch: {
        defaultToSystemStyle(value) {
            if (!value) {
                this.setThemeByUser();
            } else {
                this.setThemeBySystem();
            }
        },
        perfersDarkColor(value) {
            if (this.themeToggleTouched) {
                this.defaultToSystemStyle = false;
            }

            if (!this.defaultToSystemStyle) {
                this.saveColorPreference();
            }

            this.setDarkTheme(value);
        },
    },
    computed: {},
    methods: {
        setDarkTheme(value: boolean) {
            this.$vuetify.theme.dark = value;
        },
        setTheme(value: string) {
            const isDarkTheme = value === 'dark_theme' ? true : false;
            this.perfersDarkColor = isDarkTheme;
            this.setDarkTheme(isDarkTheme);
        },
        setThemeBySystem() {
            this.removeColorPreference();

            this.themeToggleTouched = false;
            this.defaultToSystemStyle = true;

            const isDark = this.darkMediaQuery.matches;

            this.perfersDarkColor = isDark;
            this.setDarkTheme(isDark);

            this.darkMediaQuery.addListener(
                this.colorPreferenceToggle as ColorPreferceToggle
            );
        },
        setThemeByUser(colorPreference: string | null = null) {
            this.saveColorPreference();

            this.defaultToSystemStyle = false;
            this.darkMediaQuery.removeListener(this.checkColorPreference);

            if (colorPreference) {
                this.setTheme(colorPreference);
            } else {
                this.setDarkTheme(this.perfersDarkColor);
            }
        },
        isTouched() {
            this.themeToggleTouched = true;
        },
        colorPreferenceToggle: function(e: MediaQueryListEvent) {
            if (this.defaultToSystemStyle) {
                this.setDarkTheme(e.matches as boolean);
                this.perfersDarkColor = e.matches;
            }
        },
        checkColorPreference() {
            return localStorage.getItem('color_preference');
        },
        saveColorPreference() {
            const colorTheme = this.perfersDarkColor
                ? 'dark_theme'
                : 'light_theme';

            localStorage.setItem('color_preference', colorTheme);
        },
        removeColorPreference() {
            localStorage.removeItem('color_preference');
        },
    },
});
