<template>
    <div class="landing" id="landing">
        <div class="landing-content">
            <div class="header-container">
                <h1 class="header-text">
                    <span v-scrollanimation>Hello</span>
                    <span v-scrollanimation:name="'2nd-animation'">
                        .
                    </span>
                </h1>
                <div class="sub-header">
                    <span
                        v-scrollanimation:name="'3rd-animation'"
                        class="sub-header-text"
                    >
                        I'm Elias.
                    </span>
                    <span class="sub-header-same-line">
                        <span
                            v-scrollanimation:name="'5th-animation'"
                            class="sub-header-text middle-text"
                        >
                            A
                        </span>

                        <span
                            v-scrollanimation:name="'4th-animation'"
                            class="sub-header-text"
                        >
                            web developer.
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
    data: () => ({
        animationDuration: 8000,
        appear: true,
    }),
    name: 'Landing',
});
</script>

<style lang="scss" scoped>
.landing {
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes slide-up-fade-in {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    z-index: 0;
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    .landing-content {
        font-size: 3.5rem;

        // margin-top: 25vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .header-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // margin-left: 16px;
            // margin-right: 16px;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 1;
            margin-top: 25vh;

            .header-text {
                display: flex;
                justify-content: center;
                align-items: center;

                .before-scroll-enter {
                    opacity: 0;
                }

                .scroll-enter {
                    animation: 2s slide-up-fade-in 2s ease;
                    animation-fill-mode: forwards;
                }

                .before-scroll-enter-2nd-animation {
                    opacity: 0;
                }

                .scroll-enter-2nd-animation {
                    animation: 2s ease 3s slide-up-fade-in;
                    animation-fill-mode: forwards;
                }
            }

            .sub-header {
                display: flex;
                flex-wrap: wrap;
                margin-left: 4px;

                .sub-header-text {
                    display: block;
                    font-size: 50%;
                    font-weight: 600;
                    margin-left: 0.2rem;
                }

                .middle-text {
                    margin-left: 8.5px;
                    margin-right: 5px;
                }

                .sub-header-same-line {
                    display: flex;
                    flex-wrap: wrap;
                }

                .before-scroll-enter-3rd-animation {
                    opacity: 0;
                }

                .scroll-enter-3rd-animation {
                    animation: 2s ease 4s slide-up-fade-in;
                    animation-fill-mode: forwards;
                }

                .before-scroll-enter-4th-animation {
                    opacity: 0;
                }

                .scroll-enter-5th-animation {
                    animation: 2s ease 6.5s fade-in;
                    animation-fill-mode: forwards;
                }

                .before-scroll-enter-5th-animation {
                    opacity: 0;
                }

                .scroll-enter-4th-animation {
                    animation: 2s ease 0s fade-in;
                    animation-fill-mode: forwards;
                }
            }
        }
        @media (max-width: 580px) {
            font-size: 32px;

            .header-container {
                .sub-header {
                    .sub-header-text {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
</style>
